.ppa-payment-block {
  margin-top: 92px;
}
.payment-margin-header {
  margin-top: 38px;
}
.payment-margin-card {
  margin-top: 114px;
}
@media only screen and (max-width: 420px) {
  .payment-margin-card {
    margin-top: 32px;
  }
}
#payment-options-tabs [role="tablist"] button:nth-of-type(2) {
  background: url('../../assets/img/svg/Paypal.svg') no-repeat center !important;
  background-size: 80px 20px !important;
  text-indent: -9999px !important;
}
#payment-options-tabs > div:last-of-type > div {
  margin-top: 0 !important;
}
.payment-card {
  padding: 1rem 20px;
  border-left: 4px solid #f96449;
  margin-left: 0rem;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #D2D2D2;
  border-right: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
  background-color: #FFFFFF;
}
.actual-amount {
  float: right;
}
.activate-class {
  width: 100% !important;
}
.activate-class .row {
  margin-left: 5px;
}
.activate-button-div {
  padding-left: 3% ;
  width: 100% !important;
  padding-right: 3% ;
  margin-top: 40px;
}
.activate-button {
  width: 100% !important;
}
.expiry-text {
  display: inline;
}
@media only screen and (min-width: 62rem) {
  .payment-activate-form {
    padding-right: 10% !important;
  }
  .payment-margin-header {
    margin-top: 32px;
  }
  .activate-class {
    width: 60% !important;
  }
  .activate-button {
    padding-left: 3% ;
    width: 100% !important;
    padding-right: 3% ;
  }
  .activate-button-div {
    width: 85% !important;
  }
}
@media only screen and (device-width: 768px) {
  .payment-margin-header {
    margin-top: 32px;
  }
  .activate-class {
    width: 56% !important;
  }
  .activate-button-div {
    padding-left: 3% ;
    width: 100% !important;
    padding-right: 3% ;
  }
  .activate-button {
    width: 100% !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .ppa-payment-block {
    margin-left: 100px;
  }
}
.bonus-text {
  color: #A0148C;
}
.page-subheading {
  margin-top: 56px;
}
.info-and-price {
  flex-direction: row-reverse;
  width: 100%;
}
.info-and-price .totalpay {
  flex: 1;
}
.surcharge-info {
  margin-top: 25px;
  background-color: #f0f6fd;
}
.surcharge-rate-confirm-dialog .able-ActionButton--medium-emphasis {
  visibility: hidden;
}
