.fullpage-error-container {
  margin-top: 80px;
  width: 100%;
}
.fullpage-error-container .message-us {
  padding-left: 0px !important;
}
.fullpage-error-container .chat-wrapper {
  padding-left: 15px !important;
}
.fullpage-error-container .chat-wrapper .need-help {
  margin-top: 145px;
}
.fullpage-error-container .esim-chat-wrapper .need-help {
  margin-top: 32px;
}
.fullpage-error-container .fullpage-error-message {
  margin-top: 1.5rem;
}
.esim-error-flow {
  display: flex;
  flex-flow: row;
}
@media only screen and (max-width: 420px) {
  .esim-error-flow {
    flex-flow: column-reverse;
  }
}
@media only screen and (max-width: 767px) {
  .esim-error-flow {
    flex-flow: column-reverse;
  }
}
.esim-error-message-1 {
  margin-top: 32px;
}
.startover-button {
  margin-top: 32px;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .esim-error {
    flex: 0 0 10%;
  }
}
.esim-error-img {
  height: 178px;
  width: 259px;
  margin-left: 50%;
}
@media only screen and (max-width: 420px), only screen and (max-width: 767px) {
  .esim-error-img {
    height: 104px;
    width: 104px;
    margin-top: 32px;
    margin-left: 0%;
  }
}
@media screen and (min-width: 768px) and (max-width: 780px) {
  .esim-error-img {
    margin-left: -20%;
  }
}
.esim-error-message-div {
  width: 90%;
}
@media only screen and (min-width: 62rem) {
  .fullpage-error-container {
    margin-top: 80px;
    width: 80%;
  }
}
