.credit-card-form {
  padding-top: 5%;
  padding-bottom: 2%;
}
.CreditCardPayment-hosted-field {
  height: 45px;
  margin-bottom: 1em;
  display: block;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  outline: 0;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  position: relative;
}
.Billpay-error {
  margin-bottom: 32px;
}
.CreditCardPayment-hosted-field.customselect {
  background: url('../../../assets/img/svg/chevron.svg') no-repeat right;
  background-position-x: 98%;
}
.CreditCardPayment-hosted-field.is-invalid {
  border-bottom: 1px solid red;
  transition: all 200ms ease;
}
.CreditCardPayment-invalid-message {
  visibility: hidden;
  display: none;
}
.CreditCardPayment-invalid-message.is-invalid {
  visibility: visible;
  color: red;
  display: block;
}
.CreditCard-expiry-box {
  display: flow-root;
}
.CreditCardPayment-expiry-month-year {
  width: 100%;
}
.expiry-month-year {
  display: inline-flex;
}
#cvv-error:not(.is-invalid) svg {
  display: none;
}
#cvv-error:not(.is-invalid) .field-error {
  color: var(--textSecondary);
}
.braintree-hosted-fields-focused {
  border-bottom: 2px solid blue;
  transition: all 200ms ease;
}
.braintree-hosted-fields-focused.is-invalid {
  border-bottom: 2px solid red;
  box-shadow: none;
  transition: all 200ms ease;
  outline: 0;
}
.braintree-hosted-fields-invalid {
  border-bottom: 1px solid red;
  transition: all 200ms ease;
}
#CreditCardPayment-card-image-mc,
#CreditCardPayment-card-image-visa,
#CreditCardPayment-card-image-amex {
  position: absolute;
}
#CreditCardPayment-card-image-mc.master-card {
  top: 2.5em;
  right: 5em;
}
#CreditCardPayment-card-image-visa.visa {
  top: 2.5em;
  right: 2.5em;
}
#CreditCardPayment-card-image-amex.american-express {
  top: 2.5em;
  right: 0em;
}
#CreditCardPayment-card-image-display {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 30px;
  height: 23px;
}
.credit-card-icons-enable {
  opacity: 1;
}
.credit-card-icons-disable {
  opacity: 0.1;
}
.CreditCardPayment-expiry-cardinfo {
  position: relative;
}
.CreditCardPayment-expiry {
  width: 100%;
}
.CreditCardPayment-expiry-month {
  width: 48%;
  float: left;
}
.CreditCardPayment-expiry-year {
  width: 48%;
  float: right;
}
.CreditCardPayment-cvv-div {
  width: 100%;
}
.CreditCardPayment-cvv {
  width: 38%;
}
.CreditCardPayment-braintree {
  width: 100%;
  margin-top: 32px;
}
.CreditCardPayment-submit-button {
  width: 100% !important;
  margin-top: 32px;
}
hr.credit-card-submit-margin,
.savedCreditCardCheckboxContainer hr {
  border: 0;
}
.CreditCardPayment-expiry label {
  display: inline;
}
.credit-page {
  padding-bottom: 5%;
}
.credit-footer {
  display: flow-root;
}
@media only screen and (max-width: 767px) {
  .CreditCardPayment-braintree {
    width: 100%;
  }
  .CreditCardPayment-expiry {
    width: 100%;
  }
  .CreditCardPayment-cvv {
    width: 100%;
  }
  .CreditCardPayment-submit-button {
    width: 100% !important;
  }
}
@media only screen and (min-width: 62rem) {
  .credit-card-form {
    padding-top: 5%;
    padding-bottom: 2%;
  }
}
.FinePrintError {
  height: 20px;
  width: 426px;
  color: #C91800;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  margin-left: 5px;
}
.FinePrint.is-invalid {
  color: #C91800;
  display: flex;
}
.FinePrint.is-valid {
  display: flex;
}
.custom-lock {
  padding-bottom: 10px;
  margin-right: 7px;
}
.credit-card-form .checkmark {
  top: 24px;
}
.surchargeInfoContainer .surchargeContent {
  display: flex;
  justify-content: space-between;
}
.surchargeInfoContainer .surchargeContent:not(:nth-child(1)) {
  margin-top: 20px;
}
.surchargeInfoContainer:has(.ongoingRechargeContent) .dueTodayDescription {
  margin-bottom: 25px;
}
.auto-recharge-setup-button {
  margin-top: 32px;
  width: 100% !important;
}
.submittedCard {
  display: flex;
  flex-direction: column;
}
.submittedCard .submittedCardContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.submittedCard .submittedCardContainer .submittedCardInfoContainer {
  margin-left: 12px;
  flex: auto;
}
.submittedCard .submittedCardContainer .able-icon use {
  fill: #0064d2 !important;
}
.submittedCard a:hover {
  text-decoration: none;
}
