.form-element .chev-down {
  background: url(../../../assets/img/svg/chevron-down.svg);
  display: block;
  width: 24px;
  height: 24px;
  top: 32px;
  position: absolute;
  right: 0;
  pointer-events: none;
}
.form-element select {
  width: 100%;
  display: block;
  text-align: left;
  clear: both;
  background: #fff;
  line-height: 18px !important;
  font-weight: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  border-bottom: 1px solid #949494;
  padding: 5px 20px 10px 0;
  border-radius: 0;
  min-width: 230px;
  color: #414141;
  font-family: "Telstra Akkurat";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 24px;
  background-image: none\0;
  /* Only targets IE9 */
  margin-bottom: 10px;
}
.form-element select:focus,
.form-element select:active {
  border-bottom: 1.8px solid #0064D2;
  outline: none;
}
@media only screen and (max-width: 767px) {
  .form-element select {
    width: 100% !important;
  }
}
.form-element select.has-error:focus {
  border-bottom: 2px solid #B32034 !important;
}
.form-element select option {
  text-align: left;
  height: 24px !important;
  font-size: 16px !important;
  line-height: 25px;
}
.tppa-select .td-icon {
  top: -40px;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .tppa-select .td-icon {
    top: -36px;
    left: -15px;
  }
}
