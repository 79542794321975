@import "@able/react/dist/able-react-without-LG.min.css";
@import "@able/web/dist/able-web.css";
@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1255px !important;
  }
}
html,
body,
#ppa,
#ppa > div {
  height: 100%;
}
.review-page,
.id-page,
.ppa-recharge-block,
.ppa-payment-block {
  margin-top: 88px;
}
.confirm-block {
  margin-top: 16px;
}
.ppa-container > div {
  padding-bottom: 70px;
}
.mt-global-header:before {
  height: 2px;
  background: linear-gradient(to right, #a0148c, #ffad6e 30%, #ff7aa3) !important;
}
.block {
  display: block !important;
}
.theme {
  font-weight: normal;
}
.debugger-button {
  background: transparent !important;
  border: 0px;
  font-size: 25px;
  float: right;
}
.debugger-button:focus {
  outline-color: transparent;
}
.error-msg {
  color: #B32034;
  font-size: 13px;
  font-family: "Akkurat-light", "Helvetica Neue", "Arial Narrow", Helvetica, Arial, sans-serif !important;
}
.form-element {
  position: relative;
  margin: 10px 0;
}
.form-element label {
  display: block;
  margin: 10px 0;
}
.modal-dialog {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  margin-left: auto !important;
  margin-right: auto !important;
}
.ReactModal__Overlay {
  z-index: 9999 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.ReactModal__Body--open {
  overflow: hidden;
}
.ReactModal__Content {
  margin-top: 150px !important;
  display: inherit !important;
  min-height: auto !important;
}
.ReactModal__Content .modal-body {
  padding-bottom: 20px !important;
  padding-left: 20px !important;
}
.ReactModal__Content .modal-header-no-border .header {
  padding-left: 2rem;
}
.button-as-link {
  background: none!important;
  color: #1964c8;
  border: none;
  padding: 0!important;
  font: inherit;
  cursor: pointer;
  text-decoration: underline !important;
}
.esim-wrapper .cancel-activation-link {
  cursor: pointer;
  margin-left: 0px !important;
  margin-top: 40px !important;
}
.esim-wrapper .cancel-activation-link :focus {
  outline: 2 px #004d9d;
}
.esim-wrapper .form-element label {
  font-weight: 600;
}
fieldset {
  padding: 0 !important;
  width: 100%;
}
.img-responsive {
  border: 1px solid #dadada;
}
.full-width-text {
  width: 800px;
  height: 15px;
  word-break: break-all;
}
.pdb-0 {
  padding-bottom: 0 !important;
}
.center {
  text-align: center;
}
.mbs {
  margin-bottom: 5px !important;
}
.mbsm {
  margin-bottom: 8px !important;
}
.mts {
  margin-top: 5px !important;
}
.mbm {
  margin-bottom: 10px !important;
}
.mtsm {
  margin-top: 8px !important;
}
.mtm {
  margin-top: 10px !important;
}
.mtl {
  margin-top: 16px !important;
}
.mbl {
  margin-bottom: 15px !important;
}
.mbxl {
  margin-bottom: 20px !important;
}
.mbxxl {
  margin-bottom: 25px !important;
}
.mb3xl {
  margin-bottom: 30px !important;
}
.mb-3xl {
  margin-bottom: 32px !important;
}
.mb4xl {
  margin-bottom: 40px !important;
}
.mtxl {
  margin-top: 20px !important;
}
.mtxxl {
  margin-top: 25px !important;
}
.mt-4xl {
  margin-top: 32px !important;
}
.mt-5xl {
  margin-top: 40px !important;
}
.w-100 {
  width: 100% !important;
}
.form-element input::-moz-placeholder {
  color: #333333 !important;
  opacity: 1;
}
.form-element input:-ms-input-placeholder {
  color: #333333 !important;
}
.form-element input::-webkit-input-placeholder {
  color: #333333 !important;
}
.standard.row h2 {
  margin-bottom: 10px !important;
}
@media only screen and (max-width: 767px) {
  .col-wrapper {
    padding: 5px !important;
  }
}
.t-right {
  text-align: right;
}
a {
  text-decoration: underline;
}
.hide-in-mobile {
  display: none;
}
@media (min-width: 992px) {
  .hide-in-mobile {
    display: block;
  }
}
.cta-standard-secondary {
  height: 48px !important;
  width: 95px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .cta-standard-secondary {
    float: unset !important;
    width: 86% !important;
    text-align: center;
    padding-left: 20px;
  }
}
.cta-standard {
  height: 48px !important;
  width: 113px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .cta-standard {
    float: unset !important;
    width: 86% !important;
    text-align: center;
  }
}
.cta-standard-cancel {
  height: 48px;
  width: 130px;
}
.cta-standard-landing {
  height: 48px;
  width: 135px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .cta-standard-landing {
    float: unset !important;
    width: 86% !important;
    text-align: center;
  }
}
.cta-standard-welcome {
  height: 48px;
  width: 135px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .cta-standard-welcome {
    float: unset !important;
    width: 55% !important;
  }
}
@media only screen and (max-width: 767px) {
  .close:after {
    padding-top: 0px !important;
    padding-left: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  #spectrum,
  #shade {
    display: none !important;
  }
}
.btn:after {
  padding-top: 2px !important;
}
[tabindex]:focus:not(:hover),
a:focus:not(:hover),
button.mt-button:focus:not(:hover) {
  outline: none !important;
  background: none !important;
}
.ppa-card {
  box-shadow: 0 3px 6px 1px #00000029;
  display: block;
  position: relative;
  z-index: 11;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  padding: 1rem;
  text-align: left;
  text-decoration: none;
  color: #414141;
}
.modal-top-shadow header {
  box-shadow: inset 0px 4px 0px 0px #0064d2;
}
