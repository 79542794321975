.bold {
  font-weight: bold !important;
}
.existing-number {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;
}
.existing-number li {
  float: left;
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  position: relative;
}
.existing-number label {
  display: block;
  top: -35px;
  height: 48px;
  width: 95%;
  color: #333333 !important;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
  background-color: #FFFFFF;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  position: relative;
  outline: none;
  font-family: "Akkurat-light", "Helvetica Neue", "Arial Narrow", Helvetica, Arial, sans-serif !important;
  font-weight: 900;
}
@media only screen and (max-width: 420px) {
  .existing-number label {
    width: 100% !important;
  }
}
.existing-number input {
  position: relative;
  top: -35px !important;
  height: 25px;
  display: inline-block;
}
.existing-number input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}
.existing-number input[type="radio"]:checked + label,
.Checked + label {
  background-color: #1964c8 !important;
  color: #FFFFFF !important;
}
.existing-number label {
  padding: 15px;
  border: 1px solid #CCC;
  cursor: pointer;
  z-index: 90;
}
.existing-number input[type="radio"]:focus + label {
  border: 2px solid #004d9d;
  background-color: #00aaf3 !important;
}
.form-element .text-grey-dark {
  margin-top: 25px;
}
.form-element .btn-input-control-existing {
  position: relative;
  float: left;
  width: 50%;
  min-height: 1px;
  margin-top: -15px;
}
@media only screen and (max-width: 420px) {
  .form-element .btn-input-control-existing {
    width: 100%;
  }
}
.btn.primary {
  float: right !important;
  margin-top: 20px !important;
}
@media only screen and (max-width: 420px) {
  .btn.primary {
    float: unset !important;
    width: 85%;
  }
}
.existingPlan {
  margin-left: 10px !important;
}
@media only screen and (max-width: 420px) {
  .existingPlan {
    margin-left: 0px !important;
    margin-top: -35px !important;
  }
}
@media only screen and (max-width: 420px) {
  .existingPrepaid {
    margin-bottom: -15px;
  }
}
.existingAccountNumber label {
  margin-top: 20px !important;
  width: 80%;
}
.port-message {
  display: flex;
  border: 1px solid #2C74D3;
  border-radius: 3px;
  background-color: #F4FAFF !important;
  margin-top: 25px;
  background: #fff;
  overflow: hidden;
  word-wrap: break-word;
}
.port-message .port-message-box {
  background-color: #F4FAFF !important;
  color: #1A5199 !important;
  padding-left: 20px;
  padding-top: 5px !important;
  padding-bottom: 15px;
  padding-right: 25px;
}
.port-message .port-message-box .port-title {
  font-size: 16px;
  line-height: 22px;
  margin-top: 10px;
}
.port-message .port-message-box .port-msg {
  font-size: 14px;
  line-height: 20px;
  font-family: "Akkurat-light", "Helvetica Neue", "Arial Narrow", Helvetica, Arial, sans-serif !important;
  font-weight: 600;
}
.port-message .td-icon-sm {
  top: 15px;
  position: relative;
  left: 12px;
  font-size: 25px !important;
}
.port-message .icon-information-solid {
  color: #1A5199 !important;
}
.accordion {
  margin-top: 35px;
}
.accordion .portingTermsTitle {
  height: 52px;
  background-color: #F7F7F7;
  position: relative;
  cursor: pointer;
}
@media only screen and (max-width: 420px) {
  .accordion .portingTermsTitle {
    height: 75px;
  }
}
.accordion .portingTermsTitle .title {
  height: 20px;
  padding: 15px;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  font-family: "Akkurat-light", "Helvetica Neue", "Arial Narrow", Helvetica, Arial, sans-serif !important;
  font-weight: 600;
}
@media only screen and (max-width: 420px) {
  .accordion .portingTermsTitle .title {
    width: 85%;
  }
}
.accordion .portingTermsTitle:after {
  display: block;
  position: absolute;
  top: 5px;
  width: 0;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  text-align: right;
  right: 50px;
  font-family: 'td-icons';
  display: inline-block;
  content: "\E6E3";
  color: #1964C8;
}
@media only screen and (max-width: 420px) {
  .accordion .portingTermsTitle:after {
    top: 15px;
    right: 45px;
  }
}
.accordion .portingTermsTitle[aria-selected="true"]:after {
  content: "\e6e4";
}
.accordion .portingTermsBody {
  display: block;
  padding: 0;
  animation: fadein 0.35s ease-in;
  border: 1px solid #BDBDBD;
  background-color: #FFFFFF;
  padding: 20px;
}
.accordion .portingTermsBody .content-sub-heading {
  color: #333333;
  font-size: 20px !important;
  line-height: 20px;
  font-family: "Akkurat-light", "Helvetica Neue", "Arial Narrow", Helvetica, Arial, sans-serif !important;
  font-weight: 600;
  margin: 0px !important;
  padding: 0px 0px 10px !important;
}
.accordion .portingTermsBody .terms {
  font-size: 14px !important;
  padding: 0px !important;
  margin: 0px !important;
  font-family: "Helvetica Neue", Arial, sans-serif !important;
}
.service-invalid-message {
  visibility: hidden;
}
.service-invalid-message.is-invalid {
  visibility: visible;
  color: red;
}
.port-block .port-block-header {
  padding-bottom: 17px;
}
.port-block .title-message {
  color: #414141;
  font-family: "Telstra Akkurat";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.15px;
}
.port-block .mob-button {
  margin-top: 32px;
  margin-bottom: 80px;
}
.port-block .close-svg {
  float: right !important;
  fill: black;
}
.port-block .tick-svg {
  padding-right: 11px;
}
.port-block .info-svg {
  padding-right: 6px;
}
.port-block .info-svg g {
  stroke: #0064D2;
}
.port-block .alert-bar {
  box-sizing: border-box;
  border-left: 4px solid #0064D2;
  margin-bottom: 32px;
}
.port-block .side-bar {
  box-sizing: border-box;
  border-left: 4px solid #0eaa28;
  margin-left: 1rem;
}
.port-block .terms-checked {
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  margin-top: 40px;
  margin-bottom: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.port-block .terms-checked.active {
  border: 1px solid #0064D2;
  background: #F0F6FD;
  margin-left: 1rem;
  margin-right: 1rem;
}
.port-block .terms-checked label {
  color: #414141;
  font-family: "Telstra Akkurat";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 24px;
}
.port-block .terms-checked .validation-error {
  margin-top: 20px;
}
@media only screen and (max-width: 767px) {
  .port-block .terms-checked .validation-error {
    margin-top: 40px;
  }
}
.port-block .terms-checked .form-element {
  margin: 0 !important;
  padding: 10px 20px;
}
.port-block .transfer-terms {
  max-height: 210px;
  overflow-y: scroll;
  background-color: white;
  border: 1px solid #d2d2d2;
  padding-left: 16px;
  margin-top: 16px;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-right: 16px;
}
.port-block .terms-padding {
  padding-left: 16px;
}
.terms {
  padding-top: 32px;
  padding-left: 1rem;
}
.date-info {
  padding-top: 32px;
}
.date-example {
  color: #757575;
  font-family: "Telstra Akkurat";
  font-size: 14px;
  letter-spacing: 0.18px;
}
#style-2::-webkit-scrollbar {
  width: 8px;
  background-color: white;
}
#style-2::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #D2D2D2;
}
@media only screen and (min-width: 62rem) {
  .title-message {
    margin-bottom: 0rem !important;
    color: #414141;
    font-family: "Telstra Akkurat";
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.15px;
  }
  .port-block .mob-button {
    margin-bottom: 241px;
    margin-top: 0rem;
  }
  .port-block .transfer-terms {
    width: 60%;
    margin-left: 1rem;
  }
  .port-block .terms-checked {
    width: 60%;
    margin-left: 1rem;
  }
  .port-block .terms-checked.active {
    width: 60%;
    margin-left: 1rem;
  }
  .service-data {
    width: 75%;
  }
}
@media only screen and (max-width: 767px) {
  .port-block .terms-checked .form-element .styled-checkbox + label {
    padding-bottom: 85px;
  }
}
.existing-number-form {
  margin-top: 88px;
  padding-bottom: 70px;
}
.existing-number-form .side-bar {
  margin-bottom: 2rem;
  margin-right: 0.5rem;
}
